"use client";
import { usePathname } from "next/navigation";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { PopupButton } from "@typeform/embed-react";
import { useState } from "react";
import { Loader2 } from "lucide-react";

export default function Login() {
  const pathname = usePathname();
  const t = useTranslations("landing");
  const [isLoading, setIsLoading] = useState(false);
  const urlContainsCorporates = pathname?.includes("corporates");
  const formIdSocieta = "https://visionassociation.typeform.com/NeutureAI";
  const handleClick = () => {
    setIsLoading(true);
  };
  const buttonClasses = `
  flex h-[38px] w-[96px] items-center justify-center gap-[6px] rounded-[6px] 
  bg-[#FFAF2E] text-[15px] font-medium leading-[11px] text-white 
  lg:h-[55px] lg:w-[161px] lg:text-xl lg:leading-[17px]
  transition-all duration-300 ease-in-out
  hover:bg-[#FF9F00] hover:shadow-lg
  focus:outline-none focus:ring-2 focus:ring-[#FFAF2E] focus:ring-opacity-50
  active:bg-[#FF8F00] active:scale-95
  ${isLoading ? "cursor-not-allowed opacity-70" : "cursor-pointer"}
`;

  return (
    <div className="flex h-[38px] w-[96px] items-center justify-center gap-[6px] rounded-[6px] bg-[#FFAF2E] text-[15px] font-medium leading-[11px] text-white lg:h-[55px] lg:w-[161px] lg:text-xl lg:leading-[17px]">
      {urlContainsCorporates ? (
        <PopupButton id={formIdSocieta}>
          {t("routes.contact.contact")}
        </PopupButton>
      ) : (
        <Link href="/sign-in" className={buttonClasses} onClick={handleClick}>
          {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
          {t("routes.login.signIn")}
        </Link>
      )}
    </div>
  );
}
