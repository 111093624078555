"use client";

import { useLocale } from "next-intl";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { useTranslations } from "next-intl";
import Logo from "/public/logo/logo-text-white-colored.svg?icon";
import { cn } from "@/lib/utils";
import Hamburger from "./hamburger";
import Login from "./login";

import { routeLandingItems, NavItem } from "../_config/navItems";

import LanguageSwitcherDesktop from "./language-switcher-desktop";
import { Locale } from "../../../../messages/locales";

const Navbar: React.FC = () => {
  const t = useTranslations("landing.routes");
  const pathName = usePathname();
  const locale = useLocale();

  function isNavItemActive(url: string) {
    if (url === "/") {
      return pathName === `/${locale}`;
    } else {
      return pathName === `/${locale}${url}`;
    }
  }

  return (
    <header className="fixed left-0 top-0 z-50 w-screen bg-[#0C1235]">
      <div className="flex items-center justify-between px-4 py-4 lg:px-8">
        <Logo className="w-[163px] lg:w-[298px]" />
        <div className="flex items-center gap-6">
          <div className="hidden items-center gap-10 lg:flex">
            {routeLandingItems.map((item: NavItem, index: number) => {
              return (
                <Link key={index} href={item.url}>
                  <span
                    className={cn(
                      "font-bold uppercase tracking-[0.072px] text-white decoration-2 hover:underline",
                      isNavItemActive(item.url) ? "font-bold underline " : "",
                    )}
                    style={
                      pathName === item.url
                        ? {
                            fontSize: "24px",
                            lineHeight: "17px",
                            textDecoration: "underline",
                            textDecorationColor: "#FFAF2E",
                            textDecorationThickness: "4px",
                            textUnderlineOffset: "6px",
                          }
                        : {
                            fontSize: "24px",
                            lineHeight: "17px",
                          }
                    }
                  >
                    {t(`itemsNav.${item.titleKey}`)}
                  </span>
                </Link>
              );
            })}

            {/* <LanguageSwitcherDesktop /> */}
          </div>
          <div className="flex items-center gap-4">
            <Login />
            <Hamburger />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
